import { useEffect, useRef, useState } from "react"
import { Camera as ResiumCamera } from "resium"
import { Cartesian3, Math } from "cesium"
import { Coords } from "../../types"
import styles from "./camera.module.css"

interface CameraProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cesium: any
}

const CAMERA_COORDS: Coords = [51.4552, 25.3405, 40000]
const ZOOM_STEP = 100
const MOVE_STEP = 50
const HEADING_STEP = 0.02

export const Camera = ({ cesium }: CameraProps) => {
  const [is2d, setIs2d] = useState(true)
  const [timerId, setTimerId] = useState<NodeJS.Timer>()
  const cameraRef = useRef(null)
  const camera = cesium.current.cesiumElement.camera
  const cameraCoors = Cartesian3.fromDegrees(...CAMERA_COORDS)

  const cameraMove = (cb: () => void) => {
    const id = setInterval(cb, 50)
    setTimerId(id)
  }

  const stopCameraMove = () => {
    clearInterval(timerId)
    setTimerId(undefined)
  }

  const cameraHeading = (heading: number) => {
    const { roll, pitch } = camera
    camera.setView({
      orientation: { roll, heading, pitch },
    })
  }

  const handleChangePitch = () => {
    camera.flyTo({
      destination: camera.position,
      orientation: {
        roll: camera.roll,
        heading: camera.heading,
        pitch: Math.toRadians(is2d ? -45 : -90),
      },
      duration: 0.7,
    })
    setIs2d(!is2d)
  }

  useEffect(() => {
    camera.flyTo({
      destination: cameraCoors,
      orientation: {
        heading: 0,
        pitch: Math.toRadians(-90),
        roll: 0,
      },
      pitchAdjustHeight: 5000,
    })
  }, [])

  return (
    <>
      <ResiumCamera ref={cameraRef} />
      <div className={styles.buttons}>
        <button
          className={styles.btn}
          onClick={() => camera.moveUp(MOVE_STEP)}
          onMouseDown={() => cameraMove(() => camera.moveUp(MOVE_STEP))}
          onMouseUp={stopCameraMove}
          onMouseLeave={stopCameraMove}
        >
          <span>&#8593;</span>
        </button>
        <div>
          <button
            className={styles.btn}
            onClick={() => camera.moveLeft(MOVE_STEP)}
            onMouseDown={() => cameraMove(() => camera.moveLeft(MOVE_STEP))}
            onMouseUp={stopCameraMove}
            onMouseLeave={stopCameraMove}
          >
            <span>&#8592;</span>
          </button>
          <button
            className={`${styles.btn} ${styles.centerBtn}`}
            onClick={handleChangePitch}
          >
            &#8631;
          </button>
          <button
            className={styles.btn}
            onClick={() => camera.moveRight(MOVE_STEP)}
            onMouseDown={() => cameraMove(() => camera.moveRight(MOVE_STEP))}
            onMouseUp={stopCameraMove}
            onMouseLeave={stopCameraMove}
          >
            <span>&#8594;</span>
          </button>
        </div>
        <button
          className={styles.btn}
          onClick={() => camera.moveDown(MOVE_STEP)}
          onMouseDown={() => cameraMove(() => camera.moveDown(MOVE_STEP))}
          onMouseUp={stopCameraMove}
          onMouseLeave={stopCameraMove}
        >
          <span>&#8595;</span>
        </button>
        {/* zoom & rotation */}
        <div className={styles.btnWrapper}>
          <button
            className={styles.btn}
            onClick={() => camera.zoomOut(ZOOM_STEP)}
            onMouseDown={() => cameraMove(() => camera.zoomOut(ZOOM_STEP))}
            onMouseUp={stopCameraMove}
            onMouseLeave={stopCameraMove}
          >
            -
          </button>
          <div className={styles.rotate}>
            <button
              className={styles.btn}
              onClick={() => cameraHeading(camera.heading + HEADING_STEP)}
              onMouseDown={() =>
                cameraMove(() => cameraHeading(camera.heading + HEADING_STEP))
              }
              onMouseUp={stopCameraMove}
              onMouseLeave={stopCameraMove}
            >
              &#8635;
            </button>
            <button
              className={styles.btn}
              onClick={() => cameraHeading(camera.heading - HEADING_STEP)}
              onMouseDown={() =>
                cameraMove(() => cameraHeading(camera.heading - HEADING_STEP))
              }
              onMouseUp={stopCameraMove}
              onMouseLeave={stopCameraMove}
            >
              &#8634;
            </button>
          </div>
          <button
            className={styles.btn}
            onClick={() => camera.zoomIn(ZOOM_STEP)}
            onMouseDown={() => cameraMove(() => camera.zoomIn(ZOOM_STEP))}
            onMouseUp={stopCameraMove}
            onMouseLeave={stopCameraMove}
          >
            +
          </button>
        </div>
      </div>
    </>
  )
}
