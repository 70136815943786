import { GeoJsonDataSource } from "resium"
import { Cartesian3, Math } from "cesium"
import { useEffect, useState, useRef, Fragment, ChangeEvent } from "react"
import { MixedRecord, Area } from "../types"

const getAreaJson = async () => {
  try {
    const resp = await fetch("/api/area/")
    return await resp.json()
  } catch {
    console.warn("Load areas error!")
  }
}

export const Areas = (props: { cesium: MixedRecord }) => {
  const camera = props.cesium.current.cesiumElement.camera

  const [areas, setAreas] = useState<Area[]>()
  const areasRef = useRef<Record<string, MixedRecord>>({})

  const handleAreaChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const entity =
      areasRef.current[e.target.value]?.cesiumElement.entities.values[0]
    const location = entity.properties.Location.getValue().split(", ")
    camera.flyTo({
      destination: Cartesian3.fromDegrees(+location[1], +location[0], 1500),
      orientation: { heading: 0, pitch: Math.toRadians(-90), roll: 0 },
      duration: 1,
    })
  }

  useEffect(() => {
    getAreaJson().then((data) => setAreas(data))
  }, [])

  const selectOptions = Object.keys(areasRef.current).map((v) => ({
    title: v.replaceAll("_", " "),
    value: v,
  }))

  return (
    <>
      {areas?.map((area) => {
        const [sensors, stadium] = area.geo.features
        return (
          <Fragment key={area.id}>
            <GeoJsonDataSource data={sensors} />
            <GeoJsonDataSource
              ref={(el) => (areasRef.current[area.id] = el as MixedRecord)}
              data={stadium}
            />
          </Fragment>
        )
      })}
      {selectOptions.length > 0 && (
        <select
          style={{
            position: "fixed",
            left: 20,
            bottom: 30,
            background: "#303336",
            borderRadius: 5,
            padding: 4,
            color: "#ffffff",
          }}
          onChange={handleAreaChange}
        >
          <option disabled selected>
            Select area
          </option>
          {selectOptions.map(({ value, title }) => (
            <option key={value} value={value}>
              {title}
            </option>
          ))}
        </select>
      )}
    </>
  )
}
