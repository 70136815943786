/* eslint-disable camelcase */
export const dronesConfig = {
  interval: 0.5,
  drones_count: 1,
  out_drones_count: 1,
  min_time_to_out: 15,
  max_time_to_out: 60,
}

export const flyPath = {
  fly_duration: 100,
  interval: 0.5,
  start_coords: {
    latitude: 25.2611,
    longitude: 51.6133,
    altitude: 100,
  },
  end_coords: {
    latitude: 25.2867,
    longitude: 51.5333,
    altitude: 125,
  },
}

export const polygon = {
  interval: 1.0,
  points: [
    {
      latitude: 25.250851,
      longitude: 51.535476,
      altitude: 100.0,
      fly_duration: 0,
    },
    {
      latitude: 25.251016,
      longitude: 51.535153,
      altitude: 110,
      fly_duration: 5,
    },
    {
      latitude: 25.251127,
      longitude: 51.53497,
      altitude: 107,
      fly_duration: 5,
    },
    {
      latitude: 25.251348,
      longitude: 51.534839,
      altitude: 110,
      fly_duration: 5,
    },
    {
      latitude: 25.251576,
      longitude: 51.534805,
      altitude: 120,
      fly_duration: 10,
    },
    {
      latitude: 25.252633,
      longitude: 51.534787,
      altitude: 100,
      fly_duration: 20,
    },
    {
      latitude: 25.252941,
      longitude: 51.535389,
      altitude: 110,
      fly_duration: 15,
    },
    {
      latitude: 25.252523,
      longitude: 51.535947,
      altitude: 125,
      fly_duration: 30,
    },
    {
      latitude: 25.251363,
      longitude: 51.535851,
      altitude: 110,
      fly_duration: 40,
    },
    {
      latitude: 25.250851,
      longitude: 51.535476,
      altitude: 100.0,
      fly_duration: 15,
    },
  ],
}
