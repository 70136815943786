import { useEffect, useState } from "react"
import useWebSocket from "react-use-websocket"
import { Drone } from "./Drone"
import { dronesConfig } from "../constants"
import { DroneCoords } from "../types"

const host = window.location.host
const protocol = window.location.protocol === "http:" ? "ws" : "wss"
const url = `${protocol}://${host}/ws/demo/`

type DronesCoords = Record<string, DroneCoords[]>

export const Drones = () => {
  const [dronesCoords, setDronesCoords] = useState<DronesCoords>()
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(url, {
    shouldReconnect: () => true,
  })
  const dronesCoordsList = dronesCoords && Object.values(dronesCoords)

  useEffect(() => {
    sendJsonMessage(dronesConfig)
  }, [sendJsonMessage])

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const coords = lastJsonMessage as unknown as DronesCoords
      setDronesCoords(coords)
    }
  }, [lastJsonMessage])

  if (!dronesCoordsList) return null

  return (
    <>
      {dronesCoordsList.map((drones) =>
        drones.map(({ properties, ...coords }) => (
          <Drone
            key={properties.drone_id}
            id={properties.drone_id}
            coords={[coords.longitude, coords.latitude, coords.altitude]}
            info={properties}
          />
        )),
      )}
    </>
  )
}
