import { useMemo } from "react"
import {
  Entity,
  PointGraphics,
  EntityDescription,
  Model,
  Label,
  LabelCollection,
} from "resium"
import { Cartesian3, Transforms, Color } from "cesium"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import model from "../assets/CesiumDrone.glb"
import { Coords, DroneInfo } from "../types"

interface DroneProps {
  coords: Coords
  info: DroneInfo
  id: string
}

export const Drone = (props: DroneProps) => {
  const { coords, id: ID, info } = props

  const matrix = useMemo(() => {
    return Transforms.eastNorthUpToFixedFrame(Cartesian3.fromDegrees(...coords))
  }, [coords])

  return (
    <Entity id={ID} name={ID} position={Cartesian3.fromDegrees(...coords)}>
      <PointGraphics pixelSize={28} color={Color.AQUA} />
      <LabelCollection
        modelMatrix={Transforms.eastNorthUpToFixedFrame(
          Cartesian3.fromDegrees(coords[0], coords[1], coords[2] + 3.5),
        )}
      >
        <Label
          id={ID}
          text={ID}
          fillColor={ID ? Color.YELLOW : Color.WHITE}
          position={new Cartesian3(...coords)}
          scale={0.5}
        />
      </LabelCollection>
      <Model
        maximumScale={1000}
        id={ID}
        color={ID ? Color.AQUA : Color.WHITE}
        minimumPixelSize={15}
        releaseGltfJson
        url={model}
        modelMatrix={matrix}
      />
      <EntityDescription>
        <div>
          <div>
            <h2 style={{ margin: "10px 0" }}>Drone information</h2>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3 style={{ margin: "8px 10px 8px 0" }}>ID:</h3>
              <p style={{ margin: 0 }}>{ID}</p>
            </div>
          </div>
          <h3 style={{ margin: "8px 0" }}>Coordinates: </h3>
          <ul>
            <li>latitude: {coords[1]}</li>
            <li>longitude: {coords[0]}</li>
            <li>altitude: {coords[2].toPrecision(5)} (metres)</li>
          </ul>
          <h3 style={{ margin: "8px 0" }}>Description: </h3>
          <p style={{ margin: 0 }}>{info.description}</p>
          <h3 style={{ margin: "8px 0 35px" }}>Model: {info.model}</h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "fixed",
              bottom: 0,
              backgroundColor: "rgba(84, 84, 84, 1)",
              left: 0,
              right: 0,
              padding: 4,
            }}
          >
            <div style={{ margin: "0 10px 0 0" }}>
              <span>Actions: </span>
            </div>
            <button
              onClick={() => {
                const result = window.confirm("Destroy " + ID + "?")

                if (result) {
                  alert(ID + " would be  destroyed")
                }
              }}
              style={{ margin: "0 5px 0 0" }}
            >
              Destroy
            </button>
            <button
              onClick={() => {
                const result = window.confirm("Do you want to land " + ID + "?")

                if (result) {
                  alert(ID + " would be landed immediatelly")
                }
              }}
            >
              Land
            </button>
          </div>
        </div>
      </EntityDescription>
    </Entity>
  )
}
