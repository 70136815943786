import { useRef, useState } from "react"
import { Viewer, Cesium3DTileset, Globe, CesiumMovementEvent } from "resium"
import {
  Cartesian2,
  createWorldTerrain,
  defined,
  Ion,
  IonResource,
  Entity,
  Math,
} from "cesium"
import { Camera } from "./Camera"
import { Drones } from "./Drones"
import { Areas } from "./Areas"
import { MixedRecord } from "../types"

Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_TOKEN as string

const terrainProvider = createWorldTerrain()

export const Map = () => {
  const cesium = useRef<MixedRecord>(null)
  const key = useRef(1)
  const [isMapReady, toggleReady] = useState(false)

  const handleClick = (e: CesiumMovementEvent) => {
    const scene = cesium?.current?.cesiumElement?.scene
    if (!scene) return
    const ellipsoid = scene.globe.ellipsoid
    const cartesian = scene.camera.pickEllipsoid(
      new Cartesian2(e.position?.x, e.position?.y),
      ellipsoid,
    )
    if (!cartesian) return
    const { latitude, longitude, height } =
      ellipsoid.cartesianToCartographic(cartesian)

    const radiansToDegrees = (radians: number) => {
      const pi = Math.PI
      return radians * (180 / pi)
    }

    console.log(radiansToDegrees(latitude), radiansToDegrees(longitude))

    return { latitude, longitude, height }
  }

  const handleEntitySelection = (selectedEntity: Entity) => {
    if (defined(selectedEntity)) {
      if (defined(selectedEntity.name)) {
        console.log(selectedEntity.name)
      } else {
        console.log("Unknown")
      }
    } else {
      console.log("Deselected.")
    }
  }

  return (
    <Viewer
      full
      terrainProvider={terrainProvider}
      useBrowserRecommendedResolution
      timeline={false}
      animation={false}
      shouldAnimate={true}
      scene3DOnly
      onClick={handleClick}
      ref={cesium}
      key={key.current}
      onSelectedEntityChange={handleEntitySelection}
      homeButton={false}
      geocoder={false} // search widget
      baseLayerPicker={false}
    >
      <Globe depthTestAgainstTerrain={false} />
      <Camera cesium={cesium} />
      {isMapReady && <Drones />}
      <Areas cesium={cesium} />
      <Cesium3DTileset
        onAllTilesLoad={() => toggleReady(true)}
        onTileFailed={() => toggleReady(false)}
        url={IonResource.fromAssetId(96188)}
        maximumMemoryUsage={500}
        cullRequestsWhileMoving={true}
      />
    </Viewer>
  )
}
